<template>
    <div class="center-relative graph">
        <highcharts  class="chart" :options="options" :ref="chartId" :id="chartId"></highcharts>
        <div class="center-absolute" v-if="hasSlot">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data','chartId','customColors'],
        data: function(){
            return {
                options: {
                    chart: {
                        type: 'pie',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingTop: 0,
                        margin:[0,0,0,0]
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    credits:{
                        enabled: false
                    },
                    xAxis: [],
                    exporting:{
                        enabled:false
                    },
                    yAxis: {
                        title: {
                            text: '',
                        },

                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: true,
                            style:{
                                color:'#768194' 
                            },
                        }
                    },
                    tooltip: {
                        enabled:true
                    },
                    plotOptions: {
                        pie: {
                            borderWidth:0,
                            innerSize: '85%',
                            dataLabels: {
                                enabled: false
                            },
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    //this.colors viene de main js
                    //colors:['red','blue','pink'],
                    series: [
                    ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                let getSerie = chart.get('SERIE');
                var chartData = this.data
                if(!(this.data instanceof Array)){
                    chartData = [this.data, 100-this.data]
                }
                if(getSerie){
                    getSerie.setData(chartData)
                } else {
                    chart.addSeries({ 
                        //xAxis: i,
                        id:'SERIE',
                        name:'SERIE',
                        data:chartData,
                    })
                }

                setTimeout( () => {
                    //   chart.reflow();
                },20)

            },
        },
        watch: {
            data: {
                handler(n, o){
                    this.load();
                },
                deep: true
            },
        },
        computed:{
            hasSlot(){
                return (Object.keys(this.$slots).length > 0) ? true: false;
            }
        },
        mounted() {

            if(this.customColors){
                var chart = this.$refs[this.chartId].chart
                chart.update({
                    colors:this.customColors
                })
            }
            this.load();
        }
    };
</script>
<style lang="scss">
    .graph{
        height: 100% !important;
        width: 100% !important;
        .chart{
            height: 100% !important;
            width: 100% !important;
            .highcharts-container{
                height: 100% !important;
                width: 100% !important;
            }
        }         
    }
    .center-relative{
        position: relative;
        .center-absolute{
            position: absolute;
            top:50%;
            left:50%;
            right:auto;
            transform: translate(-50%,-50%);
        }
    }
</style>
